.cta {
	@apply block;

	&--button {
		@apply cursor--pointer;
	}
	&--round {
		@apply w-20 h-20;
		border-radius: 50%;
	}
	&--construction {
		@apply bg-construction;
	}
	&--lhoist {
		@apply bg-lhoistdark text-white;
	}
	&--schief {
		@apply border-0;
		transform: rotate(-2deg) skew(-2deg);
	}
	&--zuume {
		@apply text-zuume text-2xl leading-9 tracking-wide;
	}
	&--spaced-normal {
		@apply px-8 py-2 mt-6;
	}
	&--button-icon {
		@apply relative border-none;
		@apply cursor--pointer;
		// @apply cursor--pointer;

		text-decoration: none;
		transition: all 0.4s ease-in-out;
		&:after {
			@apply absolute w-10 h-10 z-20 left-1/2 top-1/2;
			content: "";
			transform: translate(-50%, -50%);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		&:hover {
			scale: 1.2;
		}
	}
	&--play {
		@apply p-3;
		&:after {
			@apply w-10 h-9;
			background-image: url("../../../img/icons/icon-play.svg");
			translate: 6px 1px;
		}
	}
	&--next {
		&:after {
			background-image: url("../../../img/icons/arrow-next.svg");
		}
	}
	&--previous {
		&:after {
			background-image: url("../../../img/icons/arrow-previous.svg");
		}
	}
	&--home {
		&:after {
			background-image: url("../../../img/icons/home.svg");
		}
	}
	&--back {
		&:after {
			background-image: url("../../../img/icons/icon-back.svg");
		}
	}
	&--close {
		&:after {
			background-image: url("../../../img/icons/icon-cross.svg");
		}
	}

	&--download,
	&--start {
		@apply text-5xl text-white p-8 py-4 flex items-center gap-6 h-fit text-zuume;

		line-height: 0.4;
		cursor: url("../../../img/cursors/lhoist-curseur-pointeur.svg") 64 64, pointer;

		span {
			@apply block  text-zuume;
		}
		&:after {
			@apply inline-block static;
			transform: none;
		}
	}
	&--start:after {
		background-image: url("../../../img/icons/arrow-start.svg");
	}
	&--download:after {
		background-image: url("../../../img/icons/download.svg");
	}
}
.motion-button {
	@apply bg-transparent w-fit h-fit border-none;
}
