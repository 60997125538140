
.span-construction {
	@apply bg-construction text-zuume text-black inline-block px-4 py-2;
	transform: rotate(-2deg);
}

.classic-link {
	@apply font-bold text-black px-3 py-2;
}

.casual-link {
	@apply no-underline 
			text-black
			hover:underline
			hover:text-lhoistdark
			underline-offset-4;
	font-weight: 600;
	&--block {
		@apply block;
	}
	&--padding {
		@apply p-3;
	}
}
