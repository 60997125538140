.answer-explanation-container {
	.answer-explanation {
		@apply p-8 flex gap-x-8;
		&__type {
			@apply text-white text-4xl w-fit px-6 pr-8 py-2;
			transform: skew(-2deg) rotate(-2deg);
			&--success,
			&--success .success-icon {
				@apply bg-green-600;
			}
			&--error,
			&--error .success-icon {
				@apply bg-red-600;
			}

			.success-icon {
				@apply w-6 h-6 object-contain object-center absolute top-0 right-0  rounded-full p-2 border-solid border-white border-4;
				border-width: 6px;
				transform: translate(50%, -50%);
			}
		}

		&__title {
			@apply text-2xl lg:text-5xl py-8;
		}

		&__cover {
			@apply w-1/2;
			img {
				@apply h-auto object-contain object-center;
			}
		}
	}
	.modal-content-container__cover {
		&--safe:before,
		&--unsafe:before {
			@apply w-24 h-24 top-0 left-1/2 top-1/2  absolute bg-contain bg-center bg-no-repeat;
			content: "";
			transform: translate(-50%, -50%);
			z-index: 3;
		}
		&--unsafe:before {
			background-image: url("../../../img/icons/anwser_icon_unsafe.svg");
		}
		&--safe:before {
			background-image: url("../../../img/icons/anwser_icon_safe.svg");
		}
	}
	.continue-game {
		@apply mt-8;
	}
}
