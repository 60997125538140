.game-question-container {
	&__answer-buttons-container {
		@apply flex items-center gap-4 flex-wrap mt-12;
	}
	&__answer-button {
		@apply cursor--pointer ;
		@apply bg-neutral-100 border-none 
		rounded-full flex flex-col justify-center items-center shrink-0
		 w-fit h-auto
		 p-6;
		transition: transform 0.4s ease-out;

		&:hover {
			transform: scale(1.25);
		}

		img {
			@apply h-16 w-16 block  object-contain object-center;
		}
		aspect-ratio: 1/1;
	}
}
