.results-modal {
	.modal-content-container {
		@apply flex flex-col items-center pt-16 pb-10;
		&__celebration {
			@apply absolute top-0 left-0 w-full h-full z-10;
			pointer-events: none;
			max-height: 100%;
			overflow: hidden;
		}
		&__celebration-confettis-fixe {
			@apply absolute top-0 left-0 z-10 w-full;
			pointer-events: none;
		}
	}

	.visual-results {
		@apply relative;
		&__cup {
			@apply w-48 h-48;
		}

		&__score {
			@apply bg-neutral-200 h-12 w-12 rounded-full 
			flex 
			justify-center
			items-center
			text-3xl 
			text-zuume
			absolute
			top-0
			right-0;
			transform: translateX(100%);
		}
		.succes-indicator {
			@apply w-3 h-3 absolute top-0 right-0 bg-green-600 border-solid border-white rounded-full p-2;
			transform: translate(52%, -40%);
		}
	}

	.results-explanation {
		@apply text-center mt-6 max-w-md text-lg font-normal;
		line-height: 1.3;
	}
	.motivation-line {
		@apply font-bold mt-1 text-lg;
	}
	.cta--home {
		@apply mt-8;
	}
}

.page-container--results {
	.screen {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
}
