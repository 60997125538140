.page-container--warning {
	@apply bg-lhoist min-h-screen py-12 px-4 flex flex-col justify-center items-center;
	box-sizing: border-box;
	.warning-content__title {
		@apply bg-construction p-4 text-5xl;
		transform: rotate(-3deg);
	}
	.warning-content__cover {
		@apply max-w-64 max-h-64 my-12;
	}
	.warning-content__text {
		@apply text-white text-center text-xl max-w-2xl pb-12;
	}
	.lhoist-branding-logo {
		@apply hidden md:block;
	}
}
