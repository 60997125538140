.page-container {
	@apply overflow-hidden;
	$margin: 20px;

	h1.titling-construction {
		@apply mx-auto text-4xl;
	}

	h2.screen-subtitle {
		@apply text-6xl mt-4 mb-12;
	}

	.screen {
		@apply flex flex-col justify-center items-center relative  bg-lhoist xl:h-screen;
		// height: 100vh;
		// @apply overflow-x-hidden overflow-y-auto;
		overflow: hidden;
		@apply pb-44  2xl:pb-0 xl:pt-0;
		min-height: calc(100vh);
		box-sizing: border-box;
		@screen md {
			min-height: calc(99.8vh - $margin * 2);
			height: auto;
			width: calc(100% - $margin * 2);
			margin: $margin auto $margin auto;
		}

		&__content {
			@apply text-center pb-8 mt-28  lg:my-12 md:pb-0 pt-12 md:pt-12  px-2 md:px-16 w-fit mx-auto h-auto overflow-visible relative;

			background-image: url("../../../img/shapes-background/mask.svg");
			background-size: auto 100%;
			background-position: 90% top;
			@screen md {
				background-position: center;
				background-size: 100% 100%;
			}
			background-repeat: no-repeat;
			box-sizing: border-box;
			max-width: 90%;
			// padding-bottom: -100px;
		}
	}

	.content-page {
		@apply flex flex-col justify-center items-center relative  bg-lhoist;
		@screen md {
			min-height: calc(99.8vh - $margin * 2);
			width: calc(100% - $margin * 2);
			margin: $margin auto $margin auto;
		}
	}
}
