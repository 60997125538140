.cta--home {
	@apply mx-auto;
}
.footer {
	@apply px-10 py-24 xl:p-32 w-screen relative z-10 text-black;
	margin: 10rem calc(50% - 50vw) -50px calc(50% - 50vw) !important;

	transform: translateY(30px);

	box-sizing: border-box;
	p {
		@apply m-0;
	}
	&:after {
		@apply block w-full h-full absolute left-0 top-0 bg-white;
		content: "";
		z-index: -1;
		transform: skew(0deg, -2deg);
	}

	&__cover_worker {
		@apply absolute top-0 left-1/2;
		transform: translate(-43%, -50%);
	}
	&__app-horizontal-logo {
		@apply block w-full h-auto;
	}
	&__colophon {
		@apply flex flex-col md:flex-row gap-x-12 gap-y-4 pt-10 md:pt-24 items-start;

		.logo-lhoist {
			@apply w-40 h-auto;
		}
		.credits {
			@apply text-left leading-normal;
			p {
				width: max-content;
			}
		}
		.year {
			@apply font-bold;
		}
	}

	&__links {
		@apply flex 
		flex-col
		text-left
		md:flex-row

		md:ml-auto
		flex-wrap
		lg:justify-end
		my-0 p-0;
		li {
			list-style: none !important;
		}
		a {
			@apply no-underline 
			text-black
			px-3
			py-2
	
			block
			hover:underline
			hover:text-lhoistdark
			underline-offset-4;
			font-weight: 600;
		}
		.made_by {
			@apply w-full text-left lg:text-right text-lhoist pt-2 opacity-90;
			a {
				@apply text-lhoist inline px-0;
			}
		}
	}
}
