.timer {
	@apply absolute top-3 lg:top-0 left-1/2 z-10  py-2 px-8 flex items-center gap-x-2;
	transform: translate(-50%, -2px);
	.bg {
		@apply absolute block top-0 left-1/2 w-full h-full bg-transparent bg-white;
		z-index: -1;
		translate: -50%;
		transform: perspective(150px) rotateY(7deg);
	}
	.clock {
		@apply w-8;
	}
}
