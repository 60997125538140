.page-container--not-found {
	@apply text-white text-center;

	.cover {
		@apply max-w-lg mb-12;
	}

	h1 {
		@apply text-6xl;
	}

	p.error-description {
		@apply text-2xl pt-8;
	}
}
