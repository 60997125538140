.inner-content {
	@apply w-full px-4 md:px-8 pt-32 xl:p-32 pb-0;
	width: 100%;
	box-sizing: border-box;

	.page-title {
		@apply flex flex-col lg:flex-row gap-8 items-center lg:items-end mb-12 text-white;
		&__title {
			@apply w-fit text-center;
		}
		&__span-construction {
			@apply bg-construction text-black w-fit font-bold text-zuume 
				h-fit px-4 py-2 text-3xl font-bold text-zuume;
			transform: rotate(-2deg) skew(-1deg);
			white-space: nowrap;
		}
	}

	h1 {
		@apply text-8xl tracking-wide;
		line-height: 0.8;
	}
	h2 {
		@apply text-6xl mt-16;
	}
}
