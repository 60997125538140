body:has(dialog[open]) {
	overflow: hidden;
}
dialog {
	border: none;
	transform: skew(0deg, -0.7deg);
	background-color: transparent;
	&::backdrop {
		backdrop-filter: blur(3px) brightness(0.4);
	}

	overflow-y: auto;
	overflow-x: hidden;

	max-width: 100vw;
	margin: 0;
	padding: 0;
	width: 100%;
	max-height: 100vh;

	@screen md {
		@apply m-auto;

		width: clamp(40%, 900px, 90%);
		max-width: 80vw;
		max-height: 90dvh;
	}

	&:has(.modal-content-container__modal-cover) {
		box-sizing: border-box;
		.game-modal__close-button {
			@apply top-8;
		}
	}
}

.game-modal {
	&__close-button {
		@apply absolute top-10 right-8 bg-construction p-3 border-none;
		img {
			@apply w-6 h-6;
		}
	}
	&[unclosable="true"] {
		.game-modal__close-button {
			display: none;
		}
	}
	overflow: auto;
}

.modal-content-container {
	@apply gap-x-8
	 p-8 
	 md:px-24
	 pt-6
	 md:pt-28
	 pb-16
	 bg-white
	 md:bg-transparent;

	box-sizing: border-box;
	width: calc(100% - 20px);
	// margin: 10px auto 100px auto;
	margin: 10px auto 0px auto;

	&--grided {
		@apply grid lg:grid-cols-2 gap-y-6;
	}
	&--centered {
		.modal-content-container__content {
			@apply flex flex-col items-center justify-center text-center;
		}
	}
	&__background {
		@apply h-full w-full absolute top-0 left-0 object-fill;
	}
	@screen md {
		background-image: url("../../../img/shapes-background/modal-background.svg");
		@apply w-full;
	}
	background-position: center top;
	background-size: 100% 100%;
	background-repeat: no-repeat;

	h2 {
		@apply text-5xl py-3  md:py-8;
	}
	p {
		@apply leading-6;
	}
	&__content {
		// @apply pt-12;
	}

	&:has(.modal-content-container__modal-cover) {
		@apply mt-28 md:mt-12 mb-12 pt-28;
	}

	&:not(:has(h2)) {
		.explanation {
			@apply pt-8;
		}
	}

	&__modal-cover {
		@apply absolute top-12 md:top-0 bg-white w-24 h-24 p-6 rounded-full;
		// transform: translateY(-50%);
	}
	&__cover {
		@apply px-6 py-8 md:py-12 bg-neutral-50 relative;
		align-self: center;
		box-sizing: border-box;
		order: -1;

		@screen lg {
			order: unset;
		}
		img,
		svg {
			@apply max-h-56;
		}
		img {
			@apply md:max-h-96
			block w-full h-auto object-contain object-center;
		}
	}
}
