.app-logo-vector {
	@apply md:absolute mr-auto ml-0 top-0 left-0 z-10  bg-no-repeat  w-auto h-auto;
	background-position: left;
	background-size: 100% 100%;
	isolation: isolate;
	translate: -2px -3px;
	@screen md {
	}

	img {
		@apply w-16 md:w-24 h-auto py-2 md:py-6 px-2 md:px-12 z-10;
	}
	&:after {
		@apply absolute block top-0 left-0 w-full h-full bg-white;
		content: "";
		transform: skew(-2deg) rotate(-2deg);
		z-index: -1;
	}
}
