.cursor--open-hand {
	cursor: url("../../img/cursors/lhoist-curseur-open-hand.svg") 64 64, grab;
}
.cursor--pointer {
	cursor: url("../../img/cursors/lhoist-curseur-pointeur.svg") 64 64, pointer;
}

.cursor--grab {
	cursor: url("../../img/cursors/lhoist-curseur-drag.svg") 64 64, grabbing;
}

.cursor--loupe {
	cursor: url("../../img/cursors/lhoist-curseur-loupe-objet.svg") 64 64, zoom-in;
}

html {
	cursor: url("../../img/cursors/lhoist-curseur-open-hand.svg") 64 64, pointer;
}
button {
	@apply cursor--pointer;
}

a {
	@apply cursor-pointer;
}
// span,
// p {
// 	@apply cursor-default;
// }
