.help {
	@apply absolute top-3 lg:top-0 right-3 lg:right-0 z-10 flex items-center gap-x-3 py-2 px-3 text-zuume text-xl pr-6 border-none;
	transform: translate(3px, -2px);
	&:after {
		@apply block bg-white w-full h-full absolute top-0 left-0 z-0;
		content: "";

		transform: perspective(150px) rotateY(7deg);
		z-index: -1;
	}
	&__icon {
		@apply w-10 h-10 object-contain object-center;
	}
}
