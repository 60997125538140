.country-select {
	@apply relative my-8 flex flex-wrap gap-4 justify-center;
	&__toggle,
	&__country-list {
		@apply bg-slate-100  border-none  w-full;
	}
	&__label {
		@apply bg-construction font-bold text-4xl py-3 px-6 block text-zuume;
		line-height: 1;
	}

	&__toggle {
		@apply py-3
		text-neutral-800
		capitalize
		text-base
		flex
		pl-8
		my-0
		w-72
		md:w-80
		items-center;
		transform: skew(-6deg);

		.flag {
			@apply pr-4;
		}

		&:after {
			@apply block w-4 h-4  absolute right-5
			bg-center bg-no-repeat bg-contain;
			content: "";
			background-image: url("../../../img/ui-elements/select_deploy_icon.svg");
		}
		&:hover {
			&:after {
				filter: invert(88%) sepia(38%) saturate(1041%) hue-rotate(341deg) brightness(108%)
					contrast(88%);
			}
		}
	}

	&__country-list {
		&[aria-hidden="true"] {
			@apply hidden;
		}
		@apply max-h-64 my-0 absolute
		z-30	
		cursor--pointer
		pl-8 overflow-y-auto rounded-b-lg shadow-lg w-full;
		box-sizing: border-box;
		overflow-y: scroll;
		@apply list-none;
	}

	li {
		@apply flex items-center text-neutral-800 my-3;
		.flag {
			@apply pr-4;
		}
	}
}
