.application-logo {
	@apply text-zuume text-center;
	// animation-play-state: bg-red-500;

	scale: 0.7;
	@screen md {
		scale: 1;
	}

	&__title {
		@apply text-9xl mb-2;
	}
	&__subtitle {
		@apply text-6xl bg-construction w-fit mx-auto px-4 py-3 mb-10 inline-block;
		transform: rotate(-3deg);
	}
	&__draft {
		@apply bg-lhoistdark text-5xl text-white px-2 py-4 inline-block relative;
		@apply ml-6;
		transform: rotate(3deg) translateY(-10px);
	}
}
