.page-container--about {
	h1 {
		@apply pb-16;
	}
	.editor_inner_content {
		@apply text-center;
	}

	img.personnages {
		@apply max-w-lg w-full;
	}
	.cta--home {
		@apply mt-12;
	}
}
