.page-container--play {
	// @apply pt-32;
	$margin: 20px;
	.screen {
		@apply overflow-hidden;
	}
	.screen--game {
		@apply py-0 flex;

		width: 100%;
		margin: 0;
		height: 100dvh;

		@screen md {
			width: calc(100% - $margin * 2);
			height: calc(99.8vh - $margin * 2);
			margin: $margin auto 0 auto;
		}
	}
	.app-logo-vector {
		@apply fixed left-3 top-3;
	}
	.lhoist-blocks-search-and-find {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none; /* Chrome, Safari, Opera*/
		}
		@apply cursor--open-hand;

		:active {
			@apply cursor--grab;
		}
		@apply w-full overflow-x-scroll left-0 top-0 absolute h-full;

		&__background_picture {
			@apply top-0 h-full block;
			@apply w-auto;

			height: 100%;
			max-width: revert;
			-moz-user-select: none;
			-webkit-user-select: none;
			user-select: none;
			pointer-events: none;
		}

		&__objects-container {
			@apply w-fit h-full relative overflow-hidden;

			.lhoist-blocks-focus-object {
				@apply cursor--loupe;

				@apply absolute  w-auto block object-contain;
				img {
					@apply h-full;
				}

				&--inactive img {
					filter: contrast(50%) saturate(0%) brightness(1.3);
					pointer-events: none;
				}

				&--unsafe::after,
				&--safe::after {
					@apply block absolute top-1/2 left-1/2 w-16 h-16 2xl:h-24 2xl:w-24  bg-no-repeat bg-center bg-contain;

					content: "";
					z-index: 9;
					transform: translate(-50%, -50%);
				}
				&--unsafe::after {
					background-image: url("../../img/icons/anwser_icon_unsafe.svg");
				}
				&--safe::after {
					background-image: url("../../img/icons/anwser_icon_safe.svg");
				}
			}
		}
	}
}
