@import "./generalites/base.scss";
@import "./generalites/typography.scss";
@import "./generalites/cursors.scss";

@import "./components/Titling.scss";

@import "./components/game/Score.scss";
@import "./components/game/Timer.scss";
@import "./components/game/ExtraControls.scss";
@import "./components/game/GameQuestion.scss";
@import "./components/game/Soundcontrol.scss";
@import "./components/game/Help.scss";
@import "./components/game/GameAnswerExplanation.scss";
@import "./components/game/Tutorials.scss";

@import "./components/layout/Nav.scss";
@import "./components/layout/Page-container.scss";
@import "./components/layout/EditorInnerContent.scss";
@import "./components/layout/InnerContent.scss";
@import "./components/layout/Footer.scss";

@import "./components/ui/AppLogoMarkup.scss";
@import "./components/ui/AppLogoVector.scss";
@import "./components/ui/BrandingLogo.scss";
@import "./components/ui/CountrySelect.scss";
@import "./components/ui/LanguageSelect.scss";
@import "./components/ui/DropdownSelect.scss";
@import "./components/ui/Cta.scss";
@import "./components/ui/Loading.scss";
@import "./components/ui/Marquee.scss";

@import "./components/animation/Clouds.scss";

@import "./components/modals/Modal.scss";
@import "./components/modals/CookiesModal.scss";

@import "./pages/welcome.scss";
@import "./pages/Warning.scss";
@import "./pages/Home.scss";
@import "./pages/Profile.scss";
@import "./pages/Game.scss";
@import "./pages/Results.scss";
@import "./pages/Rating.scss";
@import "./pages/About.scss";
@import "./pages/Thematiques.scss";
@import "./pages/NotFound.scss";

p {
	@apply my-0;
}
