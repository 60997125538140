.lhoist-branding-logo {
	@apply fixed bottom-7 left-1/2 md:left-10 z-10  bg-no-repeat  w-auto h-auto;

	transform: translate(-50%, 0);
	@screen md {
		transform: translate(0, 0);
	}

	img {
		@apply w-20 md:w-24 h-auto py-6 pb-3 px-6 xl:px-8 z-10;
	}
	&:after {
		@apply absolute block top-0 left-0 w-full h-full bg-white;
		content: "";
		transform: skew(-2deg) rotate(-2deg);
		z-index: -1;
	}
}
