.page-container--thematiques {
	.inner-content {
		@apply pt-40;
	}

	h1 {
		@apply text-7xl sm:text-8xl  md:text-9xl  xl:text-9xl;
	}

	--swiper-pagination-bullet-horizontal-gap: 8px;

	.swiper-pagination-bullet {
		@apply bg-construction;
		transition: scale 0.3s ease-in-out;
		opacity: 0.9;
		scale: 1.2;
	}
	.page-title__span-construction {
		@apply xl:text-5xl;
		translate: 0 -20%;
		@screen xl {
			translate: 0 -50%;
		}
	}
	.swiper-pagination-bullet-active {
		border: 2px solid white;
		scale: 1.5;
		opacity: 1;
	}
	.swiper-pagination {
		bottom: -100px !important;
		@screen md {
			bottom: -100px !important;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		@apply bg-construction p-2 rounded-full w-auto cursor--pointer;

		aspect-ratio: 1/1;
		&:after {
			@apply w-8 h-8 block bg-contain bg-no-repeat bg-center;
			content: "";
			background-image: url("../../img/icons/arrow-next.svg");
		}

		@media screen and (max-width: 1279px) {
			top: unset;
			bottom: 0;
			translate: 0 50%;
		}
		@screen sm {
			bottom: 50% !important;
		}
	}
	.swiper-button-prev {
		&:after {
			transform: rotate(180deg);
		}
		@screen lg {
			left: -140px;
		}
	}
	.swiper-button-next {
		@screen lg {
			right: -140px;
		}
	}
	.swiper-container {
		@apply lg:mt-20 xl:mt-32 pb-48 xl:pb-24 w-full mx-auto overflow-visible;
		max-width: 1100px;
		bottom: 0;
		@screen lg {
			width: calc(100% - 280px);
		}

		.swiper-slide {
			@apply h-auto text-center;

			.cta--download {
				@apply opacity-0 pointer-events-none transition-all duration-300;
			}
		}

		.swiper-slide-visible {
			.cta--download {
				@apply opacity-100 pointer-events-auto;
			}
		}
	}

	.game-slide {
		overflow: visible !important;
		&__cover {
			@apply mx-auto border-white  border-solid  bg-white;
			box-sizing: border-box;
			width: 100%;
			border-width: 28px;
			aspect-ratio: 18/9;
			min-height: 250px;

			img {
				@apply h-full w-full object-cover object-center;
			}
		}

		&__title {
			@apply my-0  absolute top-0 left-1/2 bg-construction px-6 py-2;
			transform: translateX(-50%) translateY(-30%);
			transition: transform 0.3s ease-in-out;
			&:hover {
				transform: translateX(-50%) translateY(-50%) scale(1.2);
			}
			a {
				@apply text-black text-3xl text-zuume no-underline block;
			}
		}

		.cta--play {
			@apply absolute bottom-0 left-1/2;
			transform: translate(-50%, 50%);
			transform-origin: 0 50%;
		}
		.cta--download {
			@apply w-fit text-4xl mx-auto absolute left-1/2;
			transform: translate(-50%, calc(100% + 40px));
			transform-origin: 0 50%;
		}
	}

	.swiper-slide:not(.swiper-slide-active) {
		opacity: 0.7;
		.cta--play {
			@apply hidden;
		}
	}
}
