.page-container--welcome {
	@apply bg-lhoist flex flex-col items-center justify-center text-center pb-24 md:pb-0;
	min-height: 100dvh;

	.application-logo {
		@apply mb-0 scale-75 md:scale-100;
		&__title {
			@apply text-white;
		}
		&__subtitle {
		}
	}
	.truck-animation {
		margin-top: -40px;
		svg {
			@apply py-3 max-h-60 lg:max-h-80;
		}
	}
	.interaction-buttons {
		@apply flex flex-col md:flex-row items-stretch gap-y-2 gap-x-6;

		.cta--start,
		.language-select {
			@apply h-auto;
		}
		.cta--start {
			@apply py-2;
		}
	}
}
