.page-container--profile {
	.screen__content {
		@apply w-full md:w-fit;
	}
	.profile-select {
		@apply grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 list-none gap-1 2xl:px-2 max-w-screen-xl;
		padding-inline-start: 0;
		&__profile-type {
			@apply flex flex-col justify-center items-center py-10 px-3 cursor--pointer gap-2 w-fit mx-auto;
			transition: all 0.3s ease-in-out;
			&:hover,
			&--selected {
				@apply bg-slate-100;
				transform: scale(1.05) rotate(1deg);
			}
			&:hover:nth-child(even),
			&--selected:nth-child(even) {
				transform: scale(1.05) rotate(-1deg);
			}
			&[is-current="true"] {
				@apply bg-slate-100;
				transform: scale(1.1) rotate(-1deg);
			}
			&[is-current="true"]:nth-child(even) {
				transform: scale(1.1) rotate(1deg);
			}
			img {
				@apply w-auto h-32;
				pointer-events: none;
			}
		}
		.titling-construction {
			@apply mt-4 text-2xl;
		}
		// &__profile-type:hover,
		// &__profile-type--selected {
		// 	@apply bg-neutral-100;
		// }
	}
	.cta--next {
		@apply mx-auto mb-8;
		// transform: translateY(50%);
	}
}
