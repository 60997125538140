.marquee {
	@apply w-screen bg-construction p-4 py-10 text-black text-zuume uppercase text-6xl xl:text-7xl font-bold;
	rotate: -2deg;
	translate: -20px;
	@screen md {
		margin: 2rem calc(50% - 50vw) !important;
	}

	div {
		@apply flex;
	}
	p {
		@apply text-zuume w-fit;
		white-space: nowrap;
		will-change: transform;
		animation: marquee 20s linear infinite;
	}
	span {
		@apply text-zuume w-fit mx-4;
	}
}

@keyframes marquee {
	0% {
		transform: translateX(0vw);
	}
	100% {
		transform: translateX(-100%);
	}
}
