.score {
	@apply absolute bottom-4 left-4 z-10;

	.answers-visualiser {
		@apply flex flex-col md:flex-row gap-y-3 gap-x-4 p-0 m-0;
		.answer {
			@apply bg-white shrink-0 h-10 md:h-16  list-none relative flex items-center justify-center;
			aspect-ratio: 1;
			width: auto;
			z-index: -2;

			&:nth-child(odd) {
				transform: skew(-2deg) rotate(-2deg);
			}
			&:nth-child(even) {
				transform: skew(2deg) rotate(2deg);
			}
			&:after {
				@apply absolute block top-1/2 left-1/2  bg-neutral-100;
				z-index: -1;
				width: 80%;
				height: 80%;
				content: "";
				transform: translate(-50%, -50%);
			}

			&__behaviour-type-icon {
				width: 70%;
				height: 70%;
			}
			&__sucess-indicator {
				@apply absolute right-0 top-0 w-3 h-3 p-1 rounded-full border-4 border-white border-solid;
				transform: translate(40%, -40%);
				&--success {
					@apply bg-green-600;
				}
				&--error {
					@apply bg-red-600;
				}
			}
		}
	}
}
