nav.main-menu {
	@apply absolute top-0 left-0 py-3 px-6 w-full flex gap-6 justify-between md:justify-end;
	z-index: 48;
	box-sizing: border-box;
}

.nav-menu-toggle {
	@apply w-12 h-12 md:w-16 md:h-16 bg-transparent  bg-no-repeat bg-center;
	background-image: url("../../../img/shapes-background/menu-toggle-background.svg");
	img {
		@apply w-6 h-6 md:w-8 md:h-8;
		box-sizing: border-box;
	}
}
.menu[open] {
	@apply overflow-y-auto;
}
.menu {
	@apply bg-lhoist pl-6 w-full h-screen fixed top-0 bottom-0 left-0 flex flex-col justify-between;
	height: 100dvh;
	box-sizing: border-box;
	// &[open] {
	// 	@apply block;
	// }

	.cta--close {
		@apply absolute top-8 right-8 p-8;
	}

	&__main-links {
		@apply list-none flex flex-col gap-6 md:h-full pt-24 md:pt-0 justify-center w-full pl-0 xl:pl-32;
		li {
			a {
				@apply text-white text-5xl md:text-6xl lg:text-8xl xl:text-9xl text-zuume font-bold block;
				text-decoration: none;

				&:hover {
					@apply text-lhoistdark;
				}
			}
			&:nth-child(2) {
				a {
					@apply md:ml-16 flex flex-col md:flex-row items-start md:items-center gap-x-8;
				}
			}
		}
	}
	&__legal-mentions {
		@apply md:absolute pt-20 bottom-8 right-8 flex flex-col md:flex-row gap-6 list-none;
		padding-inline-start: 0;
		a {
			@apply text-white text-xl font-semibold;
			text-decoration: none;
			&:hover {
				@apply text-lhoistdark;
			}
		}
	}
	.span-construction {
		@apply text-5xl;
	}
}

.page-container:has(.menu[open]) {
	transform: none !important;
	.main-menu .nav-menu-toggle {
		pointer-events: none;
	}
}

body:has(.menu[open]) {
	overflow: hidden;
}
