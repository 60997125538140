.cloud-animation {
	@apply w-full h-full fixed top-0 left-0;
	@apply hidden md:block;
	z-index: 90;
	position: fixed;
	z-index: 40;
	pointer-events: none;
	scale: 1.1;
	filter: blur(2px);
	svg {
		width: 100px;
		heigth: 100px;
	}
}
