@font-face {
	font-family: "Zuume";
	src: local("Zuume"), url("../../fonts/ZuumeSoft-Bold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: "Dinpro";
	src: local("Dinpro"), url("../../fonts/DINProRegular.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Dinpro";
	src: local("Dinpro"), url("../../fonts/DINProBold.otf") format("opentype");
	font-weight: 800;
	font-style: normal;
}

.text-zuume {
	font-family: "Zuume";
}
.text-din {
	font-family: "Dinpro";
	font-weight: 400;
}

* {
	@apply text-din;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Zuume";
	margin: 0;
	padding: 0;
}

// html {
// 	font-size: 14px;
// }
// @media (min-width: 420px) {
// 	html {
// 		font-size: 16px;
// 	}
// }
