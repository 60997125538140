.editor_inner_content {
	@apply text-xl;
	@apply w-full max-w-screen-md mx-auto px-4 md:px-8 pt-32 xl:p-32 pb-0 text-white;
	box-sizing: border-box;
	.page-title {
		@apply flex flex-col lg:flex-row gap-8 lg:items-end mb-12;
		&__title {
			@apply w-fit;
		}
		&__last-update {
			@apply bg-construction text-black w-fit 
			  h-fit px-4 py-2 text-3xl font-bold text-zuume;
			transform: rotate(-2deg) skew(-1deg);
			white-space: nowrap;
		}
	}

	&__wp-content {
		@apply text-left;
	}
	> * {
		@apply my-4;
	}
	&__wp-content > p,
	> p {
		@apply text-xl my-6;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply mt-4 mb-6;
	}
	h1 {
		@apply text-5xl md:text-8xl tracking-wide;
		line-height: 0.8;
	}
	h2 {
		@apply text-3xl md:text-6xl mt-16;
	}

	ul li {
		list-style: square;
		@apply pb-1;
		&::marker {
			@apply text-construction;
		}
	}
	a {
		@apply text-white underline-offset-4 hover:text-construction;
	}
	h1 strong {
		@apply bg-construction px-6 py-3 text-neutral-900 font-bold text-zuume my-1;
		transform: rotate(-1deg);
		display: inline-block;
	}

	strong {
		@apply font-bold;
	}
}
