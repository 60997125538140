$margin: 20px;
.page-container--home {
	.screen {
		@apply bg-lhoistlight;
		.screen_worker_1,
		.screen_worker_2 {
			@apply hidden lg:block fixed w-fit max-h-[50%] 2xl:max-h-[60%];
			pointer-events: none;
			// max-height: 60%;
			// height: 60%;
			bottom: -3rem;
			bottom: $margin;
		}
		.screen_worker_1 {
			left: -60px;
			width: 380px;
			height: auto;
			@screen xl {
				left: 3%;
			}
			@screen 2xl {
				left: 8%;
			}
		}
		.screen_worker_2 {
			@apply -right-16;
			right: -100px;
			width: 800px;
			height: auto;
			@screen xl {
				right: 0;
			}
			@screen 2xl {
				right: 7%;
			}
		}
		&__content {
			@apply mt-28;

			@media screen and (min-height: 926px) {
				@apply mt-0;
			}
			.cover_worker {
				@apply mx-auto;
				transform: translate(0%, -60%);
				margin-bottom: -130px;
			}

			.application_title,
			.application_subtitle {
				@apply text-zuume;
			}
			.application_title {
				@apply pb-3 text-8xl sm:text-6xl xl:text-9xl;
			}
			.application_subtitle {
				@apply text-5xl sm:text-6xl bg-construction py-2 w-fit mx-auto px-3 block md:mb-10 md:inline-block;
				transform: rotate(-3deg);
			}
			.application_draft {
				@apply bg-lhoistdark text-4xl sm:text-5xl text-white px-2 py-3 inline-block relative;
				@apply ml-6;
				transform: rotate(3deg) translateY(-10px);
			}

			.application_description {
				@apply max-w-lg 2xl:max-w-xl text-lg px-4 md:px-20 font-bold;
				margin-bottom: -40px;
			}
			.cta--play {
				@apply mx-auto z-50;
				transform: translateY(50%);
			}
		}
		&__worker-1 {
		}
	}
}
