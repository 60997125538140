.language-select {
	@apply relative flex flex-wrap gap-4 cursor--pointer;
	@apply bg-transparent;
	background-image: url("../../../img/shapes-background/language-selector-background.svg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	box-sizing: border-box;

	&__toggle,
	&__country-list {
		@apply border-none  w-full;
	}

	&__label {
		@apply bg-construction font-bold text-4xl py-3 px-6 block text-zuume;
		line-height: 1;
	}

	&__toggle {
		@apply flex
		bg-transparent 
		text-neutral-800
		capitalize
		text-sm
		md:text-base
		py-2
		md:py-3
		px-5
		
		my-0
		w-fit
		w-full
		cursor--pointer
		justify-end
		items-center;
		transform: skew(-2deg);

		width: 100%;

		.flag {
			@apply bg-slate-200 rounded-full w-6 h-6 md:w-8 md:h-8 flex justify-center items-center border-2 border-solid border-black overflow-hidden;

			img {
				@apply w-full h-full object-cover;
			}
		}

		.language {
			@apply font-bold ml-1 text-2xl text-zuume text-black mr-4;
		}

		&:after {
			@apply block w-4 h-4  right-5
			bg-center bg-no-repeat bg-contain ml-auto;
			// absolute
			content: "";
			background-image: url("../../../img/ui-elements/select_deploy_icon.svg");
		}
		&:hover {
			&:after {
				transition: all 0.3s ease-in-out;
				filter: invert(27%) sepia(40%) saturate(1344%) hue-rotate(156deg) brightness(97%)
					contrast(102%);
			}
		}
	}

	&__country-list {
		@apply cursor--pointer;
		&[aria-hidden="true"] {
			@apply hidden;
		}

		@apply my-0 
		bg-white
		py-2
	
		absolute
		top-0
		z-30
		list-none	
		pl-0
		overflow-y-auto  w-full;
		max-height: 286px;
		box-sizing: border-box;
		overflow-y: scroll;
		top: 100%;
		transform: translate(0, -10px);
	}

	li {
		@apply flex items-center text-neutral-800  pl-8 py-2 cursor--pointer;
		span {
			@apply font-bold text-slate-800;
		}

		.flag {
			@apply pr-4 w-6 h-auto;
		}
		&:hover {
			@apply bg-slate-200;
		}
	}
}

.language-select:has(.language-select__country-list[aria-hidden="false"]) {
	.language-select__toggle::after {
		transform: rotate(180deg);
	}
}

.page-container--welcome {
	.language-select {
		isolation: isolate;
		&__country-list {
			top: 0;
			transform: translate(0, calc(-100% + 10px));
			z-index: -2;
		}
	}
}
