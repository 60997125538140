.tutorial-container {
	&__slides-controls {
		@apply mt-12 flex gap-4;
	}
	&__animated-cover {
		svg {
			max-height: 300px;
		}
	}
}
