.loading {
	@apply bg-lhoist;
	width: 100vw;
	height: 100vh;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	top: 0;
	// justify-content: center;
	// align-items: center;

	&__animation {
		max-width: 50vh;
		max-height: 50vw;
		margin: 0 auto;
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
		animation: translate 2s infinite linear;
	}

	&__text {
		@apply text-7xl tracking-wider text-zuume text-white text-center pt-8;
		transform: translate(0, -50px);
	}
}

@keyframes translate {
	from {
		translate: -2vw;
	}
	to {
		translate: 2vw;
	}
}
