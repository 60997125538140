.dropdown-select {
	@apply w-80 relative;
	&__toggle,
	&__select-list {
		@apply bg-white  border-neutral-300 border border-solid  w-full;
	}

	&__toggle {
		@apply rounded-md py-3
		text-neutral-800
		capitalize
		text-base
		flex
		pl-8
		my-0
	
		items-center;

		&:after {
			@apply block w-4 h-4  absolute right-5
			bg-center bg-no-repeat bg-contain;
			content: "";
			background-image: url("../../../img/ui-elements/select_deploy_icon.svg");
		}
		&:hover {
			&:after {
				filter: invert(88%) sepia(38%) saturate(1041%) hue-rotate(341deg) brightness(108%)
					contrast(88%);
			}
		}
	}

	&__select-list {
		&[aria-hidden="true"] {
			@apply hidden;
		}
		@apply max-h-64 p-0 absolute
		z-10	
		overflow-y-auto rounded-b-lg shadow-lg w-full;
		box-sizing: border-box;
		overflow-y: scroll;
		@apply list-none;

		li {
			@apply pl-8  flex items-center text-neutral-800 py-3;
		}

		li:hover {
			@apply bg-neutral-100;
		}
	}
}
