.sound-control-pannel {
	@apply absolute bottom-0 right-0 z-10  py-2 px-4 flex items-center gap-x-2 border-none bg-transparent;
	transform: translate(10px, 10px);

	&__icon {
		@apply w-10 h-10 object-contain object-center;
	}
	&__audio-toggle {
		@apply bg-transparent border-none flex items-center gap-2 text-zuume text-xl;
	}
	&:after {
		@apply block bg-white w-full h-full absolute top-0 left-0 z-0;
		content: "";
		// translate: -50%;
		transform: perspective(150px) rotateY(7deg);
		z-index: -1;
	}
}
