.page-container--rating {
	--primary-colour: #191919;
	--secondary-colour: hsl(233 80% 70%);
	--star-colour: hsl(38 90% 55%);

	transition: 0.3s;

	.screen__content {
		@apply pb-6 mt-12;
		h1 {
			@apply text-3xl;
		}
		h2 {
			@apply text-4xl mt-6 max-w-xl mx-auto text-center leading-tight;
		}
	}

	.rating {
		@apply mx-auto my-6;
		max-width: 300px;
		transform: rotateY(180deg);

		&__items {
			@apply flex justify-center items-center;
			display: flex;
			gap: 0 0.5em;
			// align-items: center;
			// justify-content: center;

			width: 100%;
			height: 100%;

			input {
				display: none;
			}

			input:hover ~ label .star-fill,
			input:checked ~ label .star-fill {
				@apply bg-yellow-500;
			}

			label {
				width: 20%;
				aspect-ratio: 1;
				@apply cursor--pointer;
			}

			label .star-fill {
				@apply grid;
				place-items: center;
				width: 100%;
				height: 100%;
				@apply bg-slate-300;
				clip-path: polygon(
					50% 0%,
					61% 35%,
					98% 35%,
					68% 57%,
					79% 91%,
					50% 70%,
					21% 91%,
					32% 57%,
					2% 35%,
					39% 35%
				);
			}

			label:hover .label-description::after {
				@apply text-yellow-500 text-2xl;

				content: attr(data-content);
				position: fixed;
				left: 0;
				right: 0;
				text-align: center;

				// margin-top: 1em;
				margin-inline: auto;

				width: 100%;
				height: 2em;

				transform: rotateY(180deg);
			}
		}
	}

	textarea {
		@apply bg-slate-200 border-none p-4 text-lg my-4;
		width: 80%;
		@screen md {
			min-width: 600px;
		}
	}
	.cta--back {
		@apply mt-3 mx-auto;
	}
	.cta--back[disabled] {
		@apply bg-slate-300;
		cursor: not-allowed;
	}
}
